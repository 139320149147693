import React, { useState } from 'react';
import styles from './FooterComponent.module.css';
import downloadIcon from '../../assets/img/download-icon.png';

interface FooterProps {
  onInstallClick?: () => void;
  showInstallButton?: boolean;
}

const Footer: React.FC<FooterProps> = ({ onInstallClick, showInstallButton = false }) => {
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  const handleSendMessage = () => {
    setIsSending(true);
    setTimeout(() => {
      setIsSending(false);
      window.location.href = `mailto:qurantig@gmail.com?subject=Contact from Website&body=${encodeURIComponent(
        message
      )}`;
      setMessage('');
    }, 500);
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.linksSection}>
          <h2>Quick Links</h2>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/read">Read Surahs</a></li>
            <li><a href="/bookmarks">Bookmarks</a></li>
            <li><a href="/notes">Notes</a></li>
          </ul>
        </div>
        <div className={styles.contactSection}>
          <h2>Contact Us</h2>
          <textarea
            placeholder="Write your comments here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            onClick={handleSendMessage}
            disabled={!message.trim() || isSending}
          >
            {isSending ? 'Sending...' : 'Send Message'}
          </button>
        </div>
        {showInstallButton && (
          <div className={styles.downloadSection}>
            <button className={styles.installButton} onClick={onInstallClick}>
              <img src={downloadIcon} alt="Download icon" className={styles.downloadIcon} />
              Download App
            </button>
          </div>
        )}
      </div>
      <div className={styles.footerBottom}>
        <p>© 2024 QuranTig. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
