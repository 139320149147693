import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import DarkMode from "./components/theme/DarkMode"; 
import NavComponent from "./components/nav-bar/navComponent"; 
import PageView from "./views/read/suras-ayas-content.view";
import DemonstrationView from "./views/demonstrations/demonstration.view";
import SurasContentView from "./views/read/suras-content.view";
import NotesPage from './components/notes/NotesPage.component';
import BookmarksView from "./views/read/bookmarks.view";
import Footer from "./components/footer/footer.component";
import { Analytics } from "@vercel/analytics/react";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme === "dark" : false;
  });

  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);

  useEffect(() => {
    document.body.dataset.theme = isDarkMode ? "dark" : "light";
    localStorage.setItem("theme", isDarkMode ? "dark" : "light");

    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };
    
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, [isDarkMode]);

  const toggleDarkMode = () => setIsDarkMode(!isDarkMode);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      (deferredPrompt as any).prompt();
      setDeferredPrompt(null);
    }
  };

  return (  
    <Router>
      <div className="App">
        <DarkMode onToggle={toggleDarkMode} isDarkMode={isDarkMode} />
        <NavComponent isDarkMode={isDarkMode}/>
        <Routes>
          <Route path="/" element={<PageView />} />
          <Route path="/read" element={<PageView />} />
          <Route path="/demonstrations" element={<DemonstrationView />} />
          <Route path="/surah/:suraId" element={<SurasContentView />} />
          <Route path="/notes" element={<NotesPage />} />
          <Route path="/bookmarks" element={<BookmarksView />} />
        </Routes>
        <Footer onInstallClick={handleInstallClick} showInstallButton={!!deferredPrompt} />
        <Analytics /> {/* Add Analytics component here */}
      </div>
    </Router>
  );
}

export default App;
